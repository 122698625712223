import React, { useContext, useState } from "react";
import styles from "./Benefits.module.scss";
import { ReactComponent as ArrowIcon } from "../../icons/arrow-toranja.svg";
import { LanguageContext } from '../../context/LanguageContext.jsx';
import content from './content.json';
import { Parallax, useParallax } from "react-scroll-parallax";
import classNames from "classnames";

export default function Benefits() {
  const { language } = useContext(LanguageContext);
  const [onView, setOnView] = useState(false)
  const parallax = useParallax({
    onEnter: (e) => {
      setOnView(true);
    },
    onExit: (e) => {
      setOnView(false);
    }
  });

  return (
    <div ref={parallax.ref} className={styles.Benefits}>
      <div className={styles.BenefitsWrapper}>
        <div className={styles.VisitCard}>
          <Parallax className={styles.Card1} translateY={[-10, 0]} translateX={[-20, 0]}>
            <img src="/assets/images/visit-card-2.png" alt="Cartão de visita Replica" />
          </Parallax>
          <Parallax className={styles.Card2} translateY={[-15, 10]} translateX={[-30, 0]}>
            <img src="/assets/images/visit-card-1.png" alt="Cartão de visita Replica" />
          </Parallax>
        </div>
        <div className={styles.BenefitsText}>
          <p className={styles.BenefitsTitle}>
            {content.title[language]}
          </p>
          {content.benefits[language].map((benefit, i) => {
            return (
              <div key={i} className={classNames(styles.BenefitItem, { [styles.ItemMotion]: onView })}>
                {benefit}
                <ArrowIcon className={styles.ArrowIcon} />
              </div>
            )
          })}
        </div>
      </div>
    </div>
  );
}
