import React, { useState, useContext, useEffect } from 'react';
import { Modal, Box, Checkbox, FormControlLabel } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import SimpleButton from "../SimpleButton/SimpleButton.jsx"
import styles from "./QuotationModal.module.scss"
import TextFieldComp from "../TextFieldComp/TextFieldComp.jsx"
import InfoIcon from '@mui/icons-material/Info';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import colors from "../../utils/colors.json"
import { BudgetContext } from '../../context/BudgetContext.jsx';

const modalStyle = {
    bgcolor: 'background.paper',
};

export default function QuotationModal({ open, handleClose }) {
    const { setBudget } = useContext(BudgetContext)
    const [success, setSuccess] = useState(false);
    const [formData, setFormData] = useState({
        name: '',
        company: '',
        email: '',
        termAgreement: false,
        token: ''
    });

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: type === 'checkbox' ? checked : value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        var url = process.env.REACT_APP_SERVER_URL + "/User/CreateUserWithBudget";
        setSuccess(true);

        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ ...formData, token: window.sessionStorage.getItem('token-budget') }),
        }).then(response => {
            return response.json()
        }).then(data => {
            console.log(data)
            if (data.message === "User with budget created successfully!") {
                setSuccess(true);
                setBudget([]);
            }
        }).catch((e) => {
            console.error(e)
        })
    };

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
        >
            {success ? <Box className={styles.ModalStyle} sx={modalStyle}>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                    }}
                >
                    <CloseIcon />
                </IconButton>

                <p className={styles.ModalTitle} id="modal-title">
                    <CheckCircleIcon fontSize="large" sx={{ color: colors.positive_feedback }} />
                    <br />
                    Verifique seu e-mail.
                    <br />
                    Seu orçamento foi enviado!
                </p>

                <p className={styles.SendText} id="modal-title">
                    Deseja reenviar o e-mail? <a href="#">Reenviar</a>
                </p>

            </Box> : <Box className={styles.ModalForm} sx={modalStyle}>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <p className={styles.ModalTitle} id="modal-title">
                    Receba seu orçamento final por e-mail.
                </p>

                <form className={styles.Form} onSubmit={handleSubmit}>
                    <TextFieldComp
                        fullWidth
                        name="name"
                        label={"Nome"}
                        placeholder={"seu nome"}
                        value={formData.name}
                        onChange={handleChange}
                        required
                    />
                    <TextFieldComp
                        fullWidth
                        name="company"
                        label={"Empresa"}
                        placeholder={"nome da empresa"}
                        value={formData.company}
                        onChange={handleChange}
                        required
                    />
                    <TextFieldComp
                        fullWidth
                        name="email"
                        type="email"
                        label={"E-mail"}
                        placeholder={"e-mail profissional"}
                        value={formData.email}
                        onChange={handleChange}
                        required
                    />
                    <div className={styles.InfoText}>
                        <InfoIcon fontSize='small' />
                        <p>Coletamos essas informações para gerar e enviar seu orçamento.</p>
                    </div>

                    <FormControlLabel
                        control={
                            <Checkbox
                                size='small'
                                name="termAgreement"
                                checked={formData.termAgreement}
                                onChange={handleChange}
                                required
                            />
                        }
                        className={styles.TermText}
                        label="Li e concordo com os termos de uso e política de privacidade de Réplica."
                    />

                    <SimpleButton
                        fullWidth={true}
                        type="submit"
                        variant="contained"
                        size='default'
                        style={{ marginTop: 2, width: "fit-content", alignSelf: "center" }}
                        title="Enviar"
                    />
                </form>
            </Box>
            }
        </Modal >
    );
}
