import React, { useState, useContext } from "react";
import styles from "./Formats.module.scss";
import { ReactComponent as ArchiveIcon } from "../../icons/archive-icon.svg";
import { ReactComponent as ArrowIcon } from "../../icons/arrow-toranja.svg";
import ArrowButton from "../../components/ArrowButton/ArrowButton.jsx";
import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded';
import { useParallax } from "react-scroll-parallax";
import classNames from "classnames";
import { LanguageContext } from '../../context/LanguageContext.jsx';
import content from './content.json';

const formats = ['html5display', 'mp4', 'jpeg', 'mov', 'gifs', 'pdf']

export default function Formats() {
  const { language } = useContext(LanguageContext);
  const [onView, setOnView] = useState(false)
  const parallax = useParallax({
    onEnter: (e) => {
      setOnView(true);
    },
    onExit: (e) => {
      setOnView(false);
    }
  });

  return (
    <div ref={parallax.ref} className={styles.Formats}>
      <div className={styles.FormatsSticky}>
        <p className={classNames(styles.Title, { [styles.TitleMotion]: onView })}>
          {content.text[language][0]}
          <span className={styles.HighlightText}>
            {content.text[language][1]}
          </span>
          {content.text[language][2]}
        </p>

        <div className={classNames(styles.FormatsTitles, { [styles.FormatsTitlesMotion]: onView })} >
          <ArchiveIcon className={styles.ArchiveIcon} />
          <div className={styles.FormatsList}>
            <div className={styles.FormatsColumn}>
              {formats.map((format, i) => {
                return <div key={i}> <ArrowIcon className={styles.ArrowIcon} /> {format} </div>
              })}
            </div>
          </div>
        </div>

        <br />

        <div className={styles.MediaLogos}>
          <img className={classNames(styles.MediaLogosMotion, { [styles.MediaLogos1]: onView })} src="/assets/logos/google-ads.png" alt="Google Ads" />
          <img className={classNames(styles.MediaLogosMotion, { [styles.MediaLogos2]: onView })} src="/assets/logos/meta.png" alt="Google Ads" />
          <img className={classNames(styles.MediaLogosMotion, { [styles.MediaLogos3]: onView })} src="/assets/logos/X.png" alt="Google Ads" />
          <img className={classNames(styles.MediaLogosMotion, { [styles.MediaLogos4]: onView })} src="/assets/logos/tiktok-business.png" alt="Google Ads" />
          <p className={classNames(styles.MediaLogosMotion, { [styles.MediaLogos5]: onView })}>DOOH</p>
        </div>

        <div className={styles.CTAButton}>
          <ArrowButton title="Solicite seu orçamento" icon={<ArrowForwardRoundedIcon />} blue_icon={true} action={"mailto:contato@byreplica.io"} />
        </div>
      </div>
    </div>
  );
}
